import React from 'react';

export default function Footer() {
  return (
    <footer>
      <a
        href='https://projects.m9n.dev/merol/index.html'
        target='_blank'
        rel='noreferrer'
      >
        Über Merol
      </a>
      <a
        href='https://projects.m9n.dev/merol/impressum.html'
        target='_blank'
        rel='noreferrer'
      >
        Impressum
      </a>
      <a
        href='https://projects.m9n.dev/merol/datenschutz.html'
        target='_blank'
        rel='noreferrer'
      >
        Datenschutz
      </a>
    </footer>
  );
}
