import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { CameraVideoOff, UpcScan, Upload } from 'react-bootstrap-icons';
import Reader from 'react-qr-scanner';

interface Iqr {
  handleScanResult: (text: String) => void;
}

export function QR({ handleScanResult }: Iqr) {
  const [mode, setMode] = useState<'upload' | 'scan'>('upload');
  const [readerRef, setReaderRef] = useState<Reader | null>(null);
  // let ref: Reader | null = null;

  function handleScan(data: String) {
    if (data) {
      console.log('Scanned', data);
      handleScanResult(data);
    }
  }

  function handleError(err: any) {
    console.error(err);
  }

  return (
    <div>
      <Reader
        delay={100}
        style={{
          width: '100%',
        }}
        onError={handleError}
        onScan={(data: any) => handleScan(data)}
        facingMode='rear'
        legacyMode={mode === 'upload'}
        ref={setReaderRef}
      />
      {mode === 'upload' && readerRef !== null && (
        <div className='xxl-button-group'>
          <Button onClick={() => setMode('scan')} variant='merol-dark'>
            <UpcScan />
            QR Code Scannen <span>Kamera</span>
          </Button>
          <Button
            onClick={() => readerRef.openImageDialog()}
            variant='outline-merol-dark'
          >
            <Upload />
            QR Code Hochladen <span>Screenshot</span>
          </Button>
        </div>
      )}
      {mode === 'scan' && (
        <div>
          <Button
            onClick={() => setMode('upload')}
            variant='outline-merol-dark'
          >
            <CameraVideoOff />
            Zurück zum Upload
          </Button>
        </div>
      )}
    </div>
  );
}
