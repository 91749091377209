import React, { FormEvent, useState } from 'react';
import {
  Button,
  Container,
  FloatingLabel,
  Form,
  Spinner,
} from 'react-bootstrap';
import {
  PersonPlus,
  FileEarmarkMedical,
  PlusCircleDotted,
} from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import * as PATHS from '../constants/routes';
import { delay } from '../scripts/helper';
import './Guest.scss';

const formStructure = [
  {
    label: 'Person',
    description:
      'Diese Angaben werden für den Abgleich mit dem 3G Nachweis benötigt.',
    fields: [
      {
        label: 'Vorname',
        key: 'firstName',
        type: 'text',
        required: true,
      },
      {
        label: 'Nachname',
        key: 'lastName',
        type: 'text',
        required: true,
      },
      {
        label: 'Geburtsdatum',
        key: 'dob',
        type: 'date',
        required: true,
      },
    ],
  },
  {
    label: 'Besuch',
    fields: [
      {
        label: 'Datum des Besuchs',
        key: 'dateOfVisit',
        type: 'date',
        required: true,
      },
      {
        label: 'Besuchsgrund',
        key: 'reasonForVisit',
        type: 'text',
        required: false,
      },

      {
        label: 'Ansprechpartner',
        key: 'responsibleEmployee',
        type: 'responsibleEmployee',
        required: false,
      },
    ],
  },
];

type StringOfStringsObject = {
  [index: string]: string;
};

const emptyFormData: StringOfStringsObject = formStructure
  .map(group => group.fields.map(field => field.key))
  .flat()
  .reduce((a, v) => ({ ...a, [v]: '' }), {});

const formDataLabels: StringOfStringsObject = formStructure
  .map(group => group.fields.map(field => [field.key, field.label]))
  .flat()
  .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

export default function Guest() {
  let navigate = useNavigate();
  const [formData, setFormData] = useState(emptyFormData);
  const [page, setPage] = useState<'form' | 'sent'>('form');
  const [loading, setLoading] = useState<boolean>(false);

  function handleChange(name: string, value: string) {
    setFormData({
      ...formData,
      [name]: value.trim(),
    });
  }

  async function submitForm(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    console.log(formData);
    setLoading(true);
    await delay(1000);
    setLoading(false);
    setPage('sent');
  }

  function reset() {
    setFormData(emptyFormData);
    setPage('form');
  }

  switch (page) {
    case 'form':
      return (
        <Container className='guest-main'>
          <h1>Gast Anmelden</h1>
          <Form className='guestForm' onSubmit={e => submitForm(e)}>
            {formStructure.map(formGroup => (
              <div key={formGroup.label}>
                <h3>{formGroup.label}</h3>
                <p>{formGroup.description}</p>
                {formGroup.fields.map(field => (
                  <FloatingLabel
                    className='mb-3'
                    controlId={field.key}
                    key={field.key}
                    label={field.required ? `${field.label}*` : field.label}
                  >
                    <Form.Control
                      type={field.type}
                      placeholder={field.label}
                      value={formData[field.key]}
                      required={field.required}
                      onChange={e => handleChange(e.target.id, e.target.value)}
                    />
                  </FloatingLabel>
                ))}
              </div>
            ))}

            {!loading ? (
              <Button variant='merol-dark' type='submit'>
                <PersonPlus />
                Gast anmelden
              </Button>
            ) : (
              <Button variant='merol-dark' disabled>
                <Spinner
                  as='span'
                  size='sm'
                  role='status'
                  animation='border'
                  aria-hidden='true'
                />
                Gast wird angemeldet ...
              </Button>
            )}
          </Form>
        </Container>
      );

    case 'sent':
      return (
        <Container className='guest-main'>
          <h1>Gast Angemeldet</h1>
          <p>Sie bzw. Ihr Gast wurden erfolgreich angemeldet.</p>

          <div className='guestPreview'>
            {Object.entries(formData).map(([k, v]) => {
              if (k && v)
                return (
                  <div key={k}>
                    <span>{formDataLabels[k as string]}:</span>
                    {v as String}
                  </div>
                );
              return null;
            })}
          </div>

          <div className='button-group'>
            <Button onClick={reset} variant='merol-dark'>
              <PlusCircleDotted />
              Weiteren Gast anmelden
            </Button>
            <Button
              onClick={() => navigate(PATHS.Scan)}
              variant='outline-merol-dark'
            >
              <FileEarmarkMedical />
              3G Zertifikat erfassen
            </Button>
          </div>
        </Container>
      );
  }
}
