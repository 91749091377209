import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { FileEarmarkMedical, PersonPlus } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import * as PATHS from '../constants/routes';
import './Navigation.scss';

const links = [
  {
    label: '3G Zertifikat Erfassen',
    path: PATHS.Scan,
    icon: <FileEarmarkMedical />,
  },
  { label: 'Gast Anmelden', path: PATHS.Guest, icon: <PersonPlus /> },
];

export default function Navigation() {
  let navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Navbar className='navigation-wrapper'>
      <Container>
        <Navbar.Brand onClick={() => navigate(PATHS.Scan)}>
          <img
            alt='Merol Logo'
            src='/Merol_horizontal.svg'
            height='35'
            className='d-inline-block align-top'
          />
        </Navbar.Brand>
        <Nav>
          {links.map(link => (
            <Nav.Link
              onClick={() => navigate(link.path)}
              key={link.label}
              className={pathname === link.path ? 'active' : ''}
            >
              {link.icon}
              {link.label}
            </Nav.Link>
          ))}
        </Nav>
      </Container>
    </Navbar>
  );
}
