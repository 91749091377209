import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import {
  Check,
  ExclamationTriangle,
  PersonPlus,
  PlusCircleDotted,
} from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import * as PATHS from '../constants/routes';
import { QR } from '../components/QrReader';
import { delay } from '../scripts/helper';
import { parseGreenPass, parsedDataLabels } from '../scripts/parseGreenPass';
import './Scan.scss';

export function Scan() {
  let navigate = useNavigate();
  const [scanResultRaw, setScanResultRaw] = useState<String | null>(null);
  const [isGreenpassResult, setIsGreenpassResult] = useState<boolean | null>(
    null
  );
  const [greenpassData, setGreenpassData] = useState<any>(null);
  const [parsedGreenpassData, setParsedGreenpassData] = useState<any>(null);
  const [isValidCert, setIsValidCert] = useState<boolean | null>(null);

  // Validate QR Scan
  useEffect(() => {
    if (scanResultRaw) {
      const { data, parsed, err } = parseGreenPass(scanResultRaw);
      if (err) {
        console.error(err);
        setIsGreenpassResult(false);
      } else {
        setIsGreenpassResult(true);
        setGreenpassData(data);
        setParsedGreenpassData(parsed);
      }
    } else setIsGreenpassResult(null);
  }, [scanResultRaw]);

  // Send Result to Backend
  useEffect(() => {
    if (greenpassData && scanResultRaw && parsedGreenpassData) {
      // TODO:replace demo
      console.log('Send to backend RAW', scanResultRaw);
      console.log('Parsed Data', parsedGreenpassData);
      console.log('Send to backend DATA', greenpassData);
      delay(1500).then(() => {
        setIsValidCert(true);
      });
    }
  }, [greenpassData, scanResultRaw, parsedGreenpassData]);

  function reset() {
    setScanResultRaw(null);
    setIsGreenpassResult(null);
    setGreenpassData(null);
    setParsedGreenpassData(null);
    setIsValidCert(null);
  }

  // 1. Scan
  if (!scanResultRaw) {
    return (
      <>
        <Container className='scan-main'>
          <h1>3G Zertifikat Erfassen</h1>
          <p>
            Scannen Sie ihren Greenpass-kompatiblen 3G Nachweis, z.b. aus
            folgenden Apps:
          </p>
          <div className='app_logos'>
            <img
              src='/img/coronawarn_logo_small.png'
              alt='Coronawarn App Logo'
            />
            <img src='/img/covpass_logo_small.png' alt='Covpass App Logo' />
          </div>

          <div className='qr-wrapper'>
            <QR handleScanResult={(text: String) => setScanResultRaw(text)} />
          </div>

          <h2>Aktuelle 3G Informationen</h2>
          <p>
            Mit Merol können Sie schnell und datenschutzkonform ihren 3G Status
            erfassen. Die in der{' '}
            <a href='https://map.schnelltestportal.de/'>Übersicht</a> mit einem
            Europa Logo markierten Schnelltestzentren können ein Greenpass
            Zertifikat über die Corona Warn App ausstellen. Falls sie kein
            Greenpass-kompatibles Zertifikat haben, können Vorgesetzte und
            EmpfangsmitarbeiterInnen den 3G Status in Merol erfassen.
          </p>
          <Row className='three-g-info'>
            <Col xs={9} md={3}>
              <h5>Geimpft</h5>
              <span className='valid'>Aktuelle Gültigkeit:</span>
              <span className='valid'>dauerhaft</span>
            </Col>
            <Col xs={9} md={3}>
              <h5>Genesen</h5>
              <span className='valid'>Aktuelle Gültigkeit:</span>
              <span className='valid'>6 Monate</span>
            </Col>
            <Col xs={9} md={3}>
              <h5>Getestet</h5>
              <span className='valid'>Aktuelle Gültigkeit:</span>
              <span className='valid'>24h (Antigen), 48h (PCR)</span>
            </Col>
          </Row>

          <div className='privacy'>
            <p>Datenschutzhinweis</p>
            <span>
              Folgende Daten werden verarbeitet: Vorname, Nachname,
              Geburtsdatum, Gültigkeit des Zertifikats (von bis), Art des
              Zertifikats und Scan-Datum. Als Auftragsverarbeiter gemäß Art. 28
              DSGVO sind wir Ihrem Unternehmen gegenüber zur Einhaltung aller
              datenschutzrechtlichen Anforderungen verpflichtet. Nähere
              Informationen erhalten Sie bei Ihrer:m Ansprechpartner:in zum
              Thema Datenschutz.
            </span>
          </div>
        </Container>
      </>
    );
  }

  if (scanResultRaw && !isGreenpassResult) {
    return (
      <>
        <Container className='scan-main'>
          <h1>QR Code Ungültig</h1>
          <p>Bitte Scannen Sie ihren kompatiblen Green Pass 3G Nachweis.</p>
          <p>
            Falls ihr Nachweis nicht Greenpass-kompatibel ist, können auch
            Vorgesetzte und EmpfangsmitarbeiterInnen den 3G Status erfassen.
          </p>
          <Button onClick={reset}>
            <PlusCircleDotted />
            Neues Zertifikat einscannen
          </Button>
        </Container>
      </>
    );
  }

  interface StringToStringObject {
    [key: string]: string;
  }

  // Confirm
  if (scanResultRaw && isGreenpassResult) {
    return (
      <>
        <Container className='scan-main'>
          <h1>3G Zertifikat Erfasst</h1>

          <p>
            Ihr Zertifikat wurde erfasst und die 3G Information übermittelt.
          </p>

          <div className='validCert'>
            {isValidCert === null && (
              <div className='pill verifying'>
                <Spinner size='sm' animation='grow' />
                <span>Zertifikat wird überprüft</span>
              </div>
            )}
            {isValidCert === true && (
              <div className='pill verified'>
                <Check />
                <span>Zertifikat ist gültig</span>
              </div>
            )}
            {isValidCert === false && (
              <div className='pill notValid'>
                <ExclamationTriangle />
                <span>Zertifikat ist ungültig</span>
              </div>
            )}
          </div>

          <div className='certPreview'>
            {Object.entries(parsedGreenpassData).map(([k, v]) => {
              if (k && v)
                return (
                  <div key={k}>
                    <span>
                      {(parsedDataLabels as StringToStringObject)[k]}:
                    </span>
                    {v as String}
                  </div>
                );
              return null;
            })}
          </div>

          <div className='button-group'>
            <Button onClick={reset} variant='merol-dark'>
              <PlusCircleDotted />
              Weiteres Zertifikat erfassen
            </Button>
            <Button
              onClick={() => navigate(PATHS.Guest)}
              variant='outline-merol-dark'
            >
              <PersonPlus />
              Gast anmelden
            </Button>
          </div>
        </Container>
      </>
    );
  }

  return <></>;
}
