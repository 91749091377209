const base45 = require('base45');
const cbor = require('cbor');
const pako = require('pako');

export function parseGreenPass(input_text) {
  try {
    const greenpassBody = input_text.substr(4);
    const decodedData = base45.decode(greenpassBody);
    const output = pako.inflate(decodedData);
    const results = cbor.decodeAllSync(output);
    const [headers1, headers2, cbor_data, signature] = results[0].value;
    const greenpassData = cbor.decodeAllSync(cbor_data);
    const relevantGreenpassData = greenpassData[0].get(-260).get(1);
    return {
      data: relevantGreenpassData,
      parsed: _parse(relevantGreenpassData),
      other: (headers1, headers2, signature),
      err: null,
    };
  } catch (error) {
    return { data: null, parsed: null, other: null, err: error };
  }
}

// fieldsDescriptions =
// {
//     '-260':
//     {
//         1:
//         {
//             'dob': 'YYYY-MM-DD', // date of birth
//             'nam': {
//                 'fn': 'Family name (surname)', // Family name (surname)
//                 'fnt': 'xxxxxxxx<xxxxxx', // Family name in special format
//                 'gn': 'Given name', // Given name
//                 'gnt': 'xxxxxxxxx', // Given name  in special format
//             },
//             'v': [
//                 {
//                     'tg': 'Targeted disease', 		// Targeted disease (COVID-19 = 840539006), see https://github.com/ehn-dcc-development/ehn-dcc-schema/raw/release/1.3.0/valuesets/disease-agent-targeted.json
//                     'vp': 'Vaccine or prophylaxis', 		// Vaccine or prophylaxis (COVID-19 = 1119349007), see https://github.com/ehn-dcc-development/ehn-dcc-schema/raw/release/1.3.0/valuesets/vaccine-prophylaxis.json
//                     'mp': 'Vaccine product id', 	// Vaccine product id, see https://github.com/ehn-dcc-development/ehn-dcc-schema/raw/release/1.3.0/valuesets/vaccine-medicinal-product.json
//                     'ma': 'Vaccine manufacturer', 	// Vaccine manufacturer, see https://github.com/ehn-dcc-development/ehn-dcc-schema/raw/release/1.3.0/valuesets/vaccine-mah-manf.json
//                     'dn': 'Doses received', // Doses received (number)
//                     'sd': 'Total number of doses', // Total number of doses (number)
//                     'dt': 'Date of vaccination', // Date of vaccination  YYYY-MM-DD (complete date without time)
//                     'co': 'Country of vaccination', // Country of vaccination, see https://github.com/ehn-dcc-development/ehn-dcc-schema/raw/release/1.3.0/valuesets/country-2-codes.json
//                     'is': 'Certificate issuer', // Certificate issuer (plain text)
//                     'ci': 'Certificate ID', // Certificate ID
//                 }
//             ],

//             'r': [
//                 {
//                     'tg': 'Targeted disease', 		// Targeted disease (COVID-19 = 840539006), see https://github.com/ehn-dcc-development/ehn-dcc-schema/raw/release/1.3.0/valuesets/disease-agent-targeted.json
//                     'fr': 'Date of first test', // Date of the holders first positive NAAT test result. YYYY-MM-DD (complete date without time).
//                     'co': 'Country', // Member State or third country in which test was carried out, see https://github.com/ehn-dcc-development/ehn-dcc-schema/raw/release/1.3.0/valuesets/country-2-codes.json
//                     'is': 'Certificate issuer', // Certificate issuer (plain text)
//                     'df': 'Valid from', // Certificate valid from YYYY-MM-DD (complete date without time).
//                     'du': 'Valid to', // Certificate valid until to YYYY-MM-DD (complete date without time).
//                     'ci': 'Certificate ID', // Certificate ID
//                 }
//             ],
//             'ver': '1.2.1'
//         }
//     }, // schema version
//     '1': 'QR code issuer country', // QR code issuer country
//     '4': 'QR code expiry date', // QR code expiry date in timestamp format (SECONDS)
//     '6': 'QR code generated'  // QR code generated in timestamp format (SECONDS)
// };

export const parsedDataLabels = {
  type: 'Typ',
  firstName: 'Vorname',
  lastName: 'Nachname',
  dob: 'geb. am',
  certDate: 'Datum d. Zertifikats',
  dosesReceived: 'Verabreichte Dosen',
  totalNumberOfDoses: 'Gesamtanzahl Dosen',
  vaccineProductId: 'Impfprodukt',
  vaccineManufacturer: 'Impfstoffhersteller',
  dateOfFirstPositiveTest: 'Positiv getestet am',
  targetedDisease: 'Krankheit',
  cert: 'Zertifikat',
};

const vaccineManufacturerNames = {
  'ORG-100001699': {
    display: 'AstraZeneca AB',
    lang: 'en',
    active: true,
    system: 'https://spor.ema.europa.eu/v1/organisations',
    version: '',
  },
  'ORG-100030215': {
    display: 'Biontech Manufacturing GmbH',
    lang: 'en',
    active: true,
    system: 'https://spor.ema.europa.eu/v1/organisations',
    version: '',
  },
  'ORG-100001417': {
    display: 'Janssen-Cilag International',
    lang: 'en',
    active: true,
    system: 'https://spor.ema.europa.eu/v1/organisations',
    version: '',
  },
  'ORG-100031184': {
    display: 'Moderna Biotech Spain S.L.',
    lang: 'en',
    active: true,
    system: 'https://spor.ema.europa.eu/v1/organisations',
    version: '',
  },
  'ORG-100006270': {
    display: 'Curevac AG',
    lang: 'en',
    active: true,
    system: 'https://spor.ema.europa.eu/v1/organisations',
    version: '',
  },
  'ORG-100013793': {
    display: 'CanSino Biologics',
    lang: 'en',
    active: true,
    system: 'https://spor.ema.europa.eu/v1/organisations',
    version: '',
  },
  'ORG-100020693': {
    display: 'China Sinopharm International Corp. - Beijing location',
    lang: 'en',
    active: true,
    system: 'https://spor.ema.europa.eu/v1/organisations',
    version: '',
  },
  'ORG-100010771': {
    display: 'Sinopharm Weiqida Europe Pharmaceutical s.r.o. - Prague location',
    lang: 'en',
    active: true,
    system: 'https://spor.ema.europa.eu/v1/organisations',
    version: '',
  },
  'ORG-100024420': {
    display:
      'Sinopharm Zhijun (Shenzhen) Pharmaceutical Co. Ltd. - Shenzhen location',
    lang: 'en',
    active: true,
    system: 'https://spor.ema.europa.eu/v1/organisations',
    version: '',
  },
  'ORG-100032020': {
    display: 'Novavax CZ AS',
    lang: 'en',
    active: true,
    system: 'https://spor.ema.europa.eu/v1/organisations',
    version: '',
  },
  'Gamaleya-Research-Institute': {
    display: 'Gamaleya Research Institute',
    lang: 'en',
    active: true,
    system: 'http://ec.europa.eu/temp/vaccinemanufacturer',
    version: '1.0',
  },
  'Vector-Institute': {
    display: 'Vector Institute',
    lang: 'en',
    active: true,
    system: 'http://ec.europa.eu/temp/vaccinemanufacturer',
    version: '1.0',
  },
  'Sinovac-Biotech': {
    display: 'Sinovac Biotech',
    lang: 'en',
    active: true,
    system: 'http://ec.europa.eu/temp/vaccinemanufacturer',
    version: '1.0',
  },
  'Bharat-Biotech': {
    display: 'Bharat Biotech',
    lang: 'en',
    active: true,
    system: 'http://ec.europa.eu/temp/vaccinemanufacturer',
    version: '1.0',
  },
};

const vaccineProducts = {
  'EU/1/20/1528': {
    display: 'Comirnaty',
    lang: 'en',
    active: true,
    system: 'https://ec.europa.eu/health/documents/community-register/html/',
    version: '',
  },
  'EU/1/20/1507': {
    display: 'COVID-19 Vaccine Moderna',
    lang: 'en',
    active: true,
    system: 'https://ec.europa.eu/health/documents/community-register/html/',
    version: '',
  },
  'EU/1/21/1529': {
    display: 'Vaxzevria',
    lang: 'en',
    active: true,
    system: 'https://ec.europa.eu/health/documents/community-register/html/',
    version: '',
  },
  'EU/1/20/1525': {
    display: 'COVID-19 Vaccine Janssen',
    lang: 'en',
    active: true,
    system: 'https://ec.europa.eu/health/documents/community-register/html/',
    version: '',
  },
  CVnCoV: {
    display: 'CVnCoV',
    lang: 'en',
    active: true,
    system: 'http://ec.europa.eu/temp/vaccineproductname',
    version: '1.0',
  },
  'Sputnik-V': {
    display: 'Sputnik-V',
    lang: 'en',
    active: true,
    system: 'http://ec.europa.eu/temp/vaccineproductname',
    version: '1.0',
  },
  Convidecia: {
    display: 'Convidecia',
    lang: 'en',
    active: true,
    system: 'http://ec.europa.eu/temp/vaccineproductname',
    version: '1.0',
  },
  EpiVacCorona: {
    display: 'EpiVacCorona',
    lang: 'en',
    active: true,
    system: 'http://ec.europa.eu/temp/vaccineproductname',
    version: '1.0',
  },
  'BBIBP-CorV': {
    display: 'BBIBP-CorV',
    lang: 'en',
    active: true,
    system: 'http://ec.europa.eu/temp/vaccineproductname',
    version: '1.0',
  },
  'Inactivated-SARS-CoV-2-Vero-Cell': {
    display: 'Inactivated SARS-CoV-2 (Vero Cell)',
    lang: 'en',
    active: true,
    system: 'http://ec.europa.eu/temp/vaccineproductname',
    version: '1.0',
  },
  CoronaVac: {
    display: 'CoronaVac',
    lang: 'en',
    active: true,
    system: 'http://ec.europa.eu/temp/vaccineproductname',
    version: '1.0',
  },
  Covaxin: {
    display: 'Covaxin (also known as BBV152 A, B, C)',
    lang: 'en',
    active: true,
    system: 'http://ec.europa.eu/temp/vaccineproductname',
    version: '1.0',
  },
};

function _parse(parsedGreenpassData) {
  const parsed = {};

  let certData;
  if (Object.keys(parsedGreenpassData).includes('v')) {
    parsed.type = 'Impfung';
    certData = parsedGreenpassData['v'][0];
  } else if (Object.keys(parsedGreenpassData).includes('t')) {
    parsed.type = 'Testung';
    certData = parsedGreenpassData['t'][0];
  } else if (Object.keys(parsedGreenpassData).includes('r')) {
    parsed.type = 'Genesung';
    certData = parsedGreenpassData['r'][0];
  }

  parsed.firstName = parsedGreenpassData.nam.gn;
  parsed.lastName = parsedGreenpassData.nam.fn;
  parsed.dob = parsedGreenpassData.dob;

  parsed.certDate = certData.dt || certData.sc || certData.df;

  if (certData.dn) parsed.dosesReceived = certData.dn;
  if (certData.sd) parsed.totalNumberOfDoses = certData.sd;
  if (certData.mp)
    parsed.vaccineProductId = vaccineProducts[certData.mp].display;
  if (certData.ma)
    parsed.vaccineManufacturer = vaccineManufacturerNames[certData.ma].display;
  if (certData.fr) parsed.dateOfFirstPositiveTest = certData.fr;

  if (certData.tg) {
    parsed.targetedDisease =
      certData.tg === '840539006'
        ? 'COVID-19'
        : `${certData.tg} (Not COVID-19)`;
  }
  // if (certData.ci) parsed.cert = certData.ci; // Certificate

  return parsed;
}
