import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import * as PATHS from './constants/routes';

import Navigation from './components/Navigation';
import Footer from './components/Footer';

import Guest from './pages/Guest';
import { Scan } from './pages/Scan';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Navigation />
        <Routes>
          <Route path={PATHS.Scan} element={<Scan />} />
          <Route path={PATHS.Guest} element={<Guest />} />
          <Route path='*' element={<Navigate to={PATHS.Scan} />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
